
import { Component, Vue, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import moment from "moment";
import { IOrderListRequest, IOrder } from "@/types/order";
import { IPagination, ISelectItem } from "@/types";
import { IUserListRequest } from "@/types/user";
import { IWarehouseListRequest } from "@/types/warehouse";
import { ISupplierListRequest } from "@/types/supplier";
import { IProductListRequest } from "@/types/product";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // order
  @Action("order/adminGetList")
  public getList!: (request: IOrderListRequest) => Promise<boolean>;

  @Getter("order/list")
  public orderList!: IOrder[];

  @Getter("order/pagination")
  public pagination!: IPagination<IOrder[]>;

  @Mutation("order/clear")
  public clear!: () => void;

  // user
  @Action("user/adminGetList")
  public getUserList!: (request: IUserListRequest) => Promise<boolean>;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  @Mutation("user/clear")
  public userClear!: () => void;

  // warehouse
  @Action("warehouse/adminGetList")
  public getWarehouseList!: (
    request: IWarehouseListRequest
  ) => Promise<boolean>;

  @Getter("warehouse/selectItem")
  public warehouseList!: ISelectItem[];

  @Mutation("warehouse/clear")
  public warehouseClear!: () => void;

  // supplier
  @Action("supplier/adminGetList")
  public getSupplierList!: (request: ISupplierListRequest) => Promise<boolean>;

  @Getter("supplier/selectItem")
  public supplierList!: ISelectItem[];

  @Mutation("supplier/clear")
  public supplierClear!: () => void;

  // product
  @Action("product/adminGetList")
  public getProductList!: (request: IProductListRequest) => Promise<boolean>;

  @Getter("product/selectItem")
  public productList!: ISelectItem[];

  @Mutation("product/clear")
  public productClear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeSearchQuery() {
    this.queryToParams();
    await this.getList(this.params);
  }

  // パラメータ定義
  public params: IOrderListRequest = {
    page: 1,
    search: "",
    multiple_order_status: [],
    search_span_start: "",
    search_span_end: "",
    sort_date_column: "",
    sort_direction: "",
    user: 0,
    warehouse: 0,
    supplier: 0,
    product: 0,
    is_asap: false,
    fixed_delivery_date_undecided: false,
    is_memo: false,
    is_remark: false
  };

  //
  // variables
  //
  public order_status = Vue.prototype.$orderStatus;
  public add_span_end = false;
  public span_end_show = true;
  public span_day_show = false;
  public rules = {};
  public menu = {
    start: false,
    end: false,
    day: false
  };

  public sort_items = [
    { value: "", text: "選択なし" },
    { value: "asc", text: "昇順" },
    { value: "desc", text: "降順" }
  ];

  public headers = [
    { text: "発注番号", value: "order_no", sortable: false },
    { text: "発注日", value: "order_date", sortable: false },
    { text: "サプライヤ名", value: "short_name", sortable: false },
    { text: "発注状況", value: "order_status", sortable: false },
    {
      text: "希望納品日",
      value: "desired_delivery_date",
      sortable: false
    },
    {
      text: "入荷予定日",
      value: "fixed_delivery_date",
      sortable: false
    },
    { text: "配送先", value: "destination", sortable: false },
    { text: "備考", value: "remark", sortable: false },
    { text: "商品名", value: "product_name", sortable: false },
    { text: "品番", value: "product_code", sortable: false },
    { text: "発注商品メモ", value: "content", sortable: false },
    { text: "発注数量", value: "quantity", sortable: false, align: "end" },
    { text: "入荷処理", value: "storing", sortable: false }
  ];

  public status_items = [
    { value: this.order_status.order_registration, text: "発注登録" },
    { value: this.order_status.ordered, text: "発注済み" },
    { value: this.order_status.wait_storing, text: "入荷待ち" },
    { value: this.order_status.stored, text: "入荷済み" },
    { value: this.order_status.cancel_order, text: "発注キャンセル" },
    { value: this.order_status.division_storing, text: "分納" }
  ];

  public sort_date_list = [
    { value: "", text: "全て表示" },
    { value: "order_date", text: "発注日" },
    { value: "fixed_delivery_date", text: "入荷予定日" },
    { value: "desired_delivery_date", text: "希望納品日" }
  ];

  //
  // methods
  //
  public async created() {
    this.clear();
    this.userClear();
    this.warehouseClear();
    this.supplierClear();
    this.productClear();
    this.queryToParams();

    await Promise.all([
      this.getList(this.params),
      this.getUserList({ per_page: 0 }),
      this.getWarehouseList({ per_page: 0 }),
      this.getSupplierList({ per_page: 0 }),
      this.getProductList({ per_page: 0 })
    ]);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      user: query.user ? Number(query.user) : 0,
      warehouse: query.warehouse ? Number(query.warehouse) : 0,
      supplier: query.supplier ? Number(query.supplier) : 0,
      product: query.product ? Number(query.product) : 0,
      sort_date_column: query.sort_date_column
        ? decodeURI(query.sort_date_column as string)
        : "",
      sort_direction: query.sort_direction
        ? decodeURI(query.sort_direction as string)
        : "",
      search_span_start: query.search_span_start
        ? decodeURI(query.search_span_start as string)
        : "",
      search_span_end: query.search_span_end
        ? decodeURI(query.search_span_end as string)
        : "",
      page: query.page ? Number(query.page) : 1,
      search: query.search ? decodeURI(query.search as string) : "",
      multiple_order_status: this.$route.query.multiple_order_status
        ? Array.isArray(this.$route.query.multiple_order_status)
          ? this.$route.query.multiple_order_status.map(Number)
          : this.$route.query.multiple_order_status.split(",").map(Number)
        : [],
      is_asap: query.is_asap ? Boolean(query.is_asap) : false,
      fixed_delivery_date_undecided: query.fixed_delivery_date_undecided
        ? Boolean(query.fixed_delivery_date_undecided)
        : false,
      is_memo: query.is_memo ? Boolean(query.is_memo) : false,
      is_remark: query.is_remark ? Boolean(query.is_remark) : false
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    if (this.params.sort_date_column == "") {
      this.params.sort_direction = "";
    }
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          search: this.params.search,
          sort_date_column: this.params.sort_date_column,
          sort_direction: this.params.sort_direction,
          page: this.params.page ? String(this.params.page) : "1",
          multiple_order_status: this.params.multiple_order_status
            ? this.params.multiple_order_status.map(String)
            : [],
          search_span_start: this.params.search_span_start,
          search_span_end: this.params.search_span_end,
          user: this.params.user ? String(this.params.user) : "0",
          warehouse: this.params.warehouse
            ? String(this.params.warehouse)
            : "0",
          supplier: this.params.supplier ? String(this.params.supplier) : "0",
          product: this.params.product ? String(this.params.product) : "0",
          is_asap: this.params.is_asap ? String(this.params.is_asap) : "",
          fixed_delivery_date_undecided: this.params
            .fixed_delivery_date_undecided
            ? String(this.params.fixed_delivery_date_undecided)
            : "",
          is_memo: this.params.is_memo ? String(this.params.is_memo) : "",
          is_remark: this.params.is_remark ? String(this.params.is_remark) : ""
        }
      })
      .catch(() => {});
  }

  //入荷処理ボタンをクリックした際の挙動
  public storing(item: IOrder) {
    this.$router
      .push(`/admin/order/${item.id}/movement_storing/create`)
      .catch(() => {});
  }

  //行をクリックした際の挙動
  public rowClick(order: IOrder) {
    this.$router.push(`/admin/order/${order.id}`);
  }

  //日付項目の西暦省略処理
  public dayConvert(day: string) {
    return moment(day).format("MM-DD");
  }

  // 開始日、終了日、日付指定のリセット
  public clearSearchStart() {
    this.params.search_span_start = "";
    this.searchFirst();
  }

  public clearSearchEnd() {
    this.params.search_span_end = "";
    this.searchFirst();
  }

  public clearSearchSpanDay() {
    this.params.search_span_start = "";
    this.params.search_span_end = "";
    this.searchFirst();
  }

  // 日付指定チェックボックスをクリックした際の挙動
  public span_toggle() {
    if (this.add_span_end == false) {
      this.params.search_span_end = "";
      this.params.search_span_start = "";
      this.span_end_show = true;
      this.span_day_show = false;
      this.searchFirst();
    } else {
      this.params.search_span_end = "";
      this.params.search_span_start = "";
      this.span_end_show = false;
      this.span_day_show = true;
      this.params.search_span_end = this.params.search_span_start;
      this.searchFirst();
    }
  }

  // 日付指定した際の挙動
  public async searchFirstSpanDay() {
    this.params.page = 1;
    this.params.search_span_end = this.params.search_span_start;
    await this.paramsToQuery();
  }

  // 期間選択時のバリデーション
  public spanEndRule() {
    if (
      !this.params.search_span_end ||
      !this.params.search_span_start ||
      this.params.search_span_end >= this.params.search_span_start
    ) {
      return true;
    }

    return "正しい日付を入力してください";
  }
}
